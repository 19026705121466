<template>
	<Header type="entry" />
	<div id="app">
		<div class="layout layout--entry">
			<div class="main-wrapper">
				<main class="main">
					<slot />
				</main>
			</div>

			<ClientOnly>
				<FeaturedImages />
				<template #fallback>
					<div class="entry-grid">
						<div class="entry-grid__list">
							<div class="entry-grid__item" v-for="i in 15" :key="i">
								<Skeleton size="100%" />
							</div>
						</div>
					</div>
				</template>
			</ClientOnly>

		</div>
		<Footer type="entry" no-start />
	</div>
</template>
