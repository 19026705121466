<script setup lang="ts">
import { editGoogleImageResolution } from '@/helpers/googleImageResolutions';

const { $api } = useNuxtApp();

const { data: featuredBooks, pending } = useAsyncData(
	'featured-books',
	async () => {
		const { ok, payload } = await $api.explore.get();
		if (!ok)
			throw new Error('FeaturedImages: Failed to fetch explore books');

		return payload || [];
	},
	{
		server: false,
	},
);
</script>

<template>
	<template v-if="pending">
		<div class="entry-grid">
			<div class="entry-grid__list">
				<div class="entry-grid__item" v-for="i in 15" :key="i">
					<Skeleton size="100%" />
				</div>
			</div>
		</div>
	</template>
	<template v-else>
		<div class="entry-grid">
			<div class="entry-grid__list">
				<NuxtLink
					:to="`/book/${book.identifier}?returnUrl=${$route.fullPath}`"
					class="entry-grid__item"
					tabindex="-1"
					v-for="book in featuredBooks"
					:key="book.bookId"
				>
					<div class="entry-grid__image-wrapper">
						<img
							class="entry-grid__image"
							:src="editGoogleImageResolution(book.thumbnail, 'quality', 'explore', 'slider')"
							:alt="book.title"
							aria-hidden="true"
						/>
					</div>
				</NuxtLink>
			</div>
		</div>
	</template>
</template>
